import { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import Logo from '../Assets/icons/Namuvi-logo-light.png'

export default function Header({ openContactModal }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavClick = (to) => {
    if (to === 'blog') {
      navigate('/blog');
      window.scrollTo(0, 0);
    } else if (location.pathname === '/') {
      scroller.scrollTo(to, { smooth: true, duration: 500, offset: -70 });
    } else {
      navigate('/');
      setTimeout(() => {
        scroller.scrollTo(to, { smooth: true, duration: 500, offset: -70 });
      }, 100);
    }
  };

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', controlNavbar);
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, [lastScrollY]);

  return (
    <header
      className={`fixed top-4 left-1/2 transform -translate-x-1/2 w-[95%] md:w-full max-w-[1340px] z-50 rounded-2xl p-3 px-4 bg-gray-800 text-white transition-all duration-500 shadow-lg ease-in-out ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-full'
      }`}
    >
      <div className="flex justify-between items-center">
        <img src={Logo} alt="logo" className="h-9 cursor-pointer" onClick={() => handleNavClick('home')}/>
        <nav className="hidden md:flex">
          <ul className="flex space-x-8 font-semibold cursor-pointer">
            <li className="hover:text-[#F5A841]">
              <span onClick={() => handleNavClick('home')}>Home</span>
            </li>
            <li className="hover:text-[#F5A841]">
              <span onClick={() => handleNavClick('about')}>About Us</span>
            </li>
            <li className="hover:text-[#F5A841]">
              <span onClick={() => handleNavClick('services')}>Services</span>
            </li>
            <li className="hover:text-[#F5A841]">
              <span onClick={() => handleNavClick('product')}>Our Product</span>
            </li>
            <li className="hover:text-[#F5A841]">
              <span onClick={() => handleNavClick('projects')}>Projects</span>
            </li>
            <li className="hover:text-[#F5A841]">
              <span onClick={() => handleNavClick('blog')}>Blogs</span>
            </li>
          </ul>
        </nav>

        <button
          className="hidden md:block bg-[#F5A841] text-black font-semibold px-4 py-2 rounded-xl cursor-pointer hover:text-gray-100"
          onClick={openContactModal}
        >
          Contact
        </button>

        <div className="md:hidden">
          <FaBars className="text-xl cursor-pointer" onClick={toggleMenu} />
        </div>
      </div>

      {isOpen && (
        <div
          className={`absolute top-0 right-0 w-full h-screen bg-gray-900 z-50 transform ${
            isOpen ? 'translate-x-0' : 'translate-x-full'
          } transition-transform duration-500 ease-in-out`}
          style={{ margin: 0, padding: 0 }}
        >
          <div className="flex justify-end p-4">
            <FaTimes className="text-2xl text-white cursor-pointer" onClick={toggleMenu} />
          </div>
          <ul className="flex flex-col space-y-6 items-center font-semibold text-white cursor-pointer">
            <li onClick={() => { handleNavClick('home'); toggleMenu(); }}>Home</li>
            <li onClick={() => { handleNavClick('about'); toggleMenu(); }}>About Us</li>
            <li onClick={() => { handleNavClick('services'); toggleMenu(); }}>Services</li>
            <li onClick={() => { handleNavClick('projects'); toggleMenu(); }}>Projects</li>
            <li onClick={() => { handleNavClick('product'); toggleMenu(); }}>Our Product</li>
            <li><NavLink to="/blog" onClick={toggleMenu}>Blogs</NavLink></li>
            <li>
              <button
                className="bg-[#F5A841] text-black font-semibold px-4 py-2 rounded-xl"
                onClick={openContactModal}
              >
                Contact
              </button>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
}
