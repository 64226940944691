import React from 'react'
import productImg from '../Assets/product.jpg';

const Product = () => {
    return (
        <section className='max-w-[1240px] mx-auto py-12 product'>
            <div>
                <h1 className='text-3xl md:text-4xl font-bold text-center drop-shadow-lg'>
                    Our <span className='text-[#F5A841]'>Product </span>
                </h1>
            </div>
            <main className='py-16 grid grid-cols-1 md:grid-cols-2 '>
                <div>
                    <img src={productImg} alt="" className='h-[400px] md:h-[450px] w-[450px] md:w-[350px]'/>
                </div>
                <div className='flex flex-col justify-center items-center md:items-start p-2 md:p-0'>
                    <h2 className='text-2xl md:text-3xl font-bold text-center text-[#F5A841] mb-4 '>SellerO2</h2>
                    <div className='text-lg md:text-xl flex flex-col gap-2 text-center md:text-left'>
                        <p>Introducing <span className='text-[#F5A841]'>SellerO2</span>, your one-stop destination for enhancing product visibility across all e-commerce platforms.</p>
                        <p>We're dedicated to supporting sellers in maximizing their product exposure with cutting-edge technological solutions, all under one roof.</p>
                        <p className='font-bold'>Welcome to <span className='text-[#F5A841]'>SellerO2</span>, where we're here to empower sellers like you!</p>
                    </div>
                    <a href='https://sellero2.com/' target='_blank' className='mt-4 bg-[#F5A841] text-black font-semibold px-4 py-2 rounded-xl cursor-pointer hover:text-gray-100'>
                        Click here
                    </a>
                </div>
            </main>
        </section>
    )
}

export default Product