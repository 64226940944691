import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import blog1Img from "../../Assets/blogs/images/blog-1.jpeg";
import blog2Img from "../../Assets/blogs/images/blog-2.jpeg";
import blog3Img from "../../Assets/blogs/images/blog-3.jpeg";
import blog4Img from "../../Assets/blogs/images/blog-4.jpeg";
import blog5Img from "../../Assets/blogs/images/blog-5.jpeg";
import blog6Img from "../../Assets/blogs/images/blog-6.jpeg";

const blogs = [
  {
    id: 1,
    title: "How To Find Trending Topics For Blogs",
    image: blog1Img,
    keywords: "How To Find Trending Topics For Blogs",
  },
  {
    id: 2,
    title: "Future of E-Commerce with AR and VR",
    image: blog2Img,
    keywords: "",
  },
  {
    id: 3,
    title: "How E-Commerce Can Advance With AI and ML",
    image: blog3Img,
    keywords: "",
  },
  {
    id: 4,
    title: "How E-Invoice Benefits from ERP",
    image: blog4Img,
    keywords: "",
  },
  {
    id: 5,
    title: "Revolutionizing Event Management with SaaS: A Business Transformation Story",
    image: blog5Img,
    keywords: "",
  },
  {
    id: 6,
    title: "Why Businesses Need Digital Marketing in 2025",
    image: blog6Img,
    keywords: "",
  }
];

const Blog = () => {
  const navigate = useNavigate();

  const handleBlogClick = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <div className="max-w-[1300px] mx-auto blog-page py-12 md:py-24">
      <Helmet>
        <title>Our Blogs | Insights and Trends</title>
        <meta name="description" content="Explore our latest blogs on trending topics, e-commerce, SaaS, and more!" />
        <meta name="keywords" content="blogs, e-commerce, SaaS, trending topics, technology" />
      </Helmet>

      <div class="absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#8080800a_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px]">
        <div class="absolute left-0 right-0 top-0 -z-10 m-auto h-[310px] w-[310px] rounded-full bg-[#F5A841] opacity-20 blur-[100px]"></div>
      </div>
      <div className="text-center py-10 md:py-12">
        <h1 className="text-2xl md:text-4xl font-bold text-gray-800">
          Think. <span className='text-[#F5A841]'>Learn.</span> Connect.
        </h1>
        <p className="text-lg md:text-xl text-gray-600 mt-2">
        Where thought-provoking ideas meet your desire to grow and share.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-24 p-6">
        {blogs.map((blog) => (
          <div
            key={blog.id}
            className="relative cursor-pointer group hover:scale-105 transition-all duration-300"
            onClick={() => handleBlogClick(blog.id)}
          >
            <img
              src={blog.image}
              alt={blog.title}
              className="w-full h-64 object-cover rounded-lg"
            />
            <h2 className='text-lg mt-2 font-semibold'>{blog.title}</h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
