import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const details = gsap.utils.toArray(".desktopContentSection:not(:first-child)");
const photos = gsap.utils.toArray(".desktopPhoto:not(:first-child)");

gsap.set(photos, { yPercent: 101 });

const allPhotos = gsap.utils.toArray(".desktopPhoto");

// Create GSAP matchMedia
let mm = gsap.matchMedia();

// Add a media query. When it matches, the associated function will run
mm.add("(min-width: 600px)", () => {
  console.log("desktop");

  ScrollTrigger.create({
    trigger: ".gallery",
    start: "top top",
    end: "bottom bottom",
    pin: ".right",
  });

  details.forEach((detail, index) => {
    let headline = detail.querySelector("h1");
    let animation = gsap.timeline()
      .to(photos[index], { yPercent: 0 })
      .set(allPhotos[index], { autoAlpha: 0 });

    ScrollTrigger.create({
      trigger: headline,
      start: "top 80%",
      end: "top 50%",
      animation: animation,
      scrub: true,
      markers: false,
    });
  });

  return () => {
    console.log("mobile");
  };
});
