import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import webdevImg from '../../Assets/services/MacBook.png';
import ecommImg from '../../Assets/services/ecomm.png';
import ecommImg2 from '../../Assets/services/ecomm-1.png';
import uiuxImg from '../../Assets/services/uiux.png';
import appdevImg from '../../Assets/services/appdev.png';
import dgtlmrktImg from '../../Assets/services/digitalmarkt.png';
import saasImg from '../../Assets/services/saas.png';

gsap.registerPlugin(ScrollTrigger);

const WhatWedo = ({ openContactModal }) => {
    const panelsRef = useRef([]);
    const ref = useRef(null);

    return (
        <main className='py-12 services'>

            <div className='sticky top-10  md:relative text-center md:mt-6 py-4'>
                <h1 className='text-3xl md:text-4xl font-bold drop-shadow-lg' style={{ fontFamily: 'Lactos, sans-serif' }}>What <span className='text-[#F5A841]'>We Do?</span></h1>
            </div>

            <div className="flex flex-col items-center justify-center min-h-screen space-y-8  md:py-0">
                <div ref={ref} className="sticky -top-[130px] md:top-0 flex items-center py-12 h-screen">
                    <div className='h-auto md:h-[660px] w-[350px] md:w-[1350px] mx-auto rounded-3xl p-4 md:p-12 transform transition-all '
                        style={{ background: 'linear-gradient(to right, #e4efe9, #93a5cf)' }}>
                        <div className='flex flex-col-reverse md:flex-row'>
                            <div className='md:w-[600px] mt-0 md:mt-36 flex flex-col gap-3 items-start'>
                                <h1 className='text-xl md:text-3xl font-bold uppercase'>Web Development</h1>
                                <p className='text-md md:text-xl'>Build scalable and innovative websites that align with your business goals using our <b>Custom Web Development</b> expertise.</p>
                                <ul className='text-sm md:text-lg list-disc ml-4'>
                                    <li>Custom Web Development</li>
                                    <li>CMS Integration</li>
                                    <li>Frontend & Backend Development</li>
                                    <li>Website Performance Optimization</li>
                                    <li>Security & Maintenance</li>
                                </ul>
                                <button
                                    className="bg-black text-white rounded-xl px-5 py-2 hover:bg-white hover:text-black"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openContactModal();
                                    }}
                                >
                                    Know More
                                </button>
                            </div>
                            <div className='hidden md:block'>
                                <img src={webdevImg} alt="Web Development" className="h-[400px] md:h-[650px] object-cover drop-shadow-2xl" />
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={(el) => (panelsRef.current[1] = el)}
                    className="sticky -top-[60px] md:top-0 flex items-center justify-center h-screen">
                    <div className='h-auto md:h-[660px] w-[350px] md:w-[1350px] mx-auto rounded-3xl p-4 md:p-12 transform transition-all'
                        style={{ background: 'linear-gradient(to right, #50A7C2, #B7F8DB)' }}>
                        <div className='flex flex-col-reverse md:flex-row'>
                            <div className='md:w-[600px] md:mt-36 flex flex-col gap-3 items-start'>
                                <h1 className='text-xl md:text-3xl font-bold uppercase '>Ecommerce App Development</h1>
                                <p className='text-md md:text-xl'>Revolutionize your online store with tailored solutions for a <b>custom ecommerce website</b> that drives results</p>
                                <ul className='text-sm md:text-lg list-disc ml-4'>
                                    <li>Custom Ecommerce Website Development</li>
                                    <li>Secure Payment Integration</li>
                                    <li>Inventory Management</li>
                                    <li>Mobile-first Design</li>
                                    <li>Marketing Tools Integration</li>
                                </ul>
                                <button
                                    className="bg-black text-white rounded-xl px-5 py-2 hover:bg-white hover:text-black"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openContactModal();
                                    }}
                                >
                                    Know More
                                </button>
                            </div>
                            <div
                                className="hidden md:block relative w-[350px] md:w-[800px] h-[400px] justify-center items-center"
                            >
                                <img
                                    src={ecommImg}
                                    alt="Ecomm"
                                    className="absolute top-0 left-0 drop-shadow-2xl h-[400px] md:h-[800px] w-[320px] md:-mt-24 md:ml-12 md:w-full"
                                />
                                <img
                                    src={ecommImg2}
                                    alt="Ecomm"
                                    className="absolute top-0 left-0 drop-shadow-2xl h-[300px] w-[300px] mt-64 -ml-2 object-cover z-10 hidden md:flex"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={(el) => (panelsRef.current[2] = el)}
                    className="sticky -top-[6px] md:top-0 flex items-center justify-center h-screen">
                    <div className='h-auto md:h-[660px] w-[350px] md:w-[1350px] mx-auto rounded-3xl p-4 md:p-12 transform transition-all'
                        style={{ background: 'linear-gradient(to right,  #FCDD58, #F39C4D)' }}>
                        <div className='flex flex-col-reverse md:flex-row'>
                            <div className='md:w-[600px] mt-0 md:mt-44 flex flex-col gap-3 items-start'>
                                <h1 className='text-xl md:text-3xl font-bold uppercase'>UI/UX Design</h1>
                                <p className='text-md md:text-xl '>Design user-friendly interfaces and experiences with our <p>UI UX development company</p> to captivate and retain your audience</p>
                                <ul className='text-sm md:text-lg list-disc ml-4'>
                                    <li>User Research and Personas</li>
                                    <li>Wireframes & Prototypes</li>
                                    <li>Interaction Design</li>
                                    <li>Responsive Interfaces</li>
                                    <li>Usability Testing</li>
                                </ul>
                                <button
                                    className="bg-black text-white rounded-xl px-5 py-2 hover:bg-white hover:text-black"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openContactModal();
                                    }}
                                >
                                    Know More
                                </button>
                            </div>
                            <div className='hidden md:block'>
                                <img src={uiuxImg} alt="App Development" className='w-[400px] md:w-[800px] h-[380px] md:h-[600px] object-cover rounded-2xl' />
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={(el) => (panelsRef.current[3] = el)}
                    className="sticky top-[60px] md:top-0 flex items-center justify-center h-screen">
                    <div className='h-auto md:h-[660px] w-[350px] md:w-[1350px] mx-auto rounded-3xl p-4 md:p-12 transform transition-all'
                        style={{ background: 'linear-gradient(to right, #efd5ff, #515ada)' }}>
                        <div className='flex flex-col-reverse md:flex-row'>
                            <div className='md:w-[600px] mt-0 md:mt-44 flex flex-col gap-3 items-start'>
                                <h1 className='text-xl md:text-3xl font-bold uppercase'>App Development</h1>
                                <p className='text-md md:text-xl'>Turn your ideas into high-performance applications with our <b>Android app development agency</b></p>
                                <ul className='text-sm md:text-lg list-disc ml-4'>
                                    <li>Native & Cross-Platform Development</li>
                                    <li>Cloud Integration</li>
                                    <li>API Development & Integration</li>
                                    <li>Post-launch Support</li>
                                    <li>Data Privacy & Security</li>
                                </ul>
                                <button
                                    className="bg-black text-white rounded-xl px-5 py-2 hover:bg-white hover:text-black"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openContactModal();
                                    }}
                                >
                                    Know More
                                </button>                            </div>
                            <div className='hidden md:block'>
                                <img src={appdevImg} alt="App Development" className='w-[800px] h-[340px] md:h-[600px] object-cover rounded-2xl' />
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={(el) => (panelsRef.current[4] = el)}
                    className="sticky top-[120px] md:top-0 flex items-center justify-center h-screen">
                    <div className='h-auto md:h-[660px] w-[350px] md:w-[1350px] mx-auto rounded-3xl p-4 md:p-12 transform transition-all'
                        style={{ background: 'linear-gradient(to right, #00d2ff, #3a47d5)' }}>
                        <div className='flex flex-col-reverse md:flex-row'>
                            <div className='md:w-[600px] mt-0 md:mt-40 flex flex-col gap-3 items-start'>
                                <h1 className='text-xl md:text-3xl font-bold uppercase'>Digital Marketing</h1>
                                <p className='text-md md:text-xl'>Transform your online presence with targeted strategies crafted by our expert <b>digital marketing agency</b> to drive engagement and conversions.</p>
                                <ul className='text-sm md:text-lg list-disc ml-4'>
                                    <li>SEO & SEM Campaigns</li>
                                    <li>Social Media Marketing</li>
                                    <li>Content Marketing Strategies</li>
                                    <li>Email Marketing</li>
                                    <li>Analytics and Reporting</li>
                                </ul>
                                <button
                                    className="bg-black text-white rounded-xl px-5 py-2 hover:bg-white hover:text-black"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openContactModal();
                                    }}
                                >
                                    Know More
                                </button>
                            </div>
                            <div className='hidden md:block'>
                                <img src={dgtlmrktImg} alt="Digital Marketing" className='h-[340px] md:h-[800px] rounded-2xl ml-4' />
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={(el) => (panelsRef.current[0] = el)}
                    className="sticky top-[130px] md:top-0 flex items-center justify-center h-screen">
                    <div className='h-auto md:h-[660px]  w-[350px] md:w-[1350px] mx-auto rounded-3xl p-4 md:p-12 transform transition-all'
                        style={{ background: 'linear-gradient(to right, #89a8d4, #507DBC)' }}>
                        <div className='flex flex-col-reverse md:flex-row'>
                            <div className='md:w-[600px] mt-0 md:mt-40 flex flex-col gap-3 items-start'>
                                <h1 className='text-xl md:text-3xl font-bold uppercase'>SaaS Application</h1>
                                <p className='text-md md:text-xl'>Empower your business with subscription-based software solutions designed to boost productivity and drive growth</p>
                                <ul className='text-sm md:text-lg list-disc ml-4 '>
                                    <li>Multi-tenant architecture</li>
                                    <li>Subscription & Billing integration</li>
                                    <li>API & Third-party integrations</li>
                                    <li>Custom Analytics</li>
                                    <li>Security and Compliance</li>
                                </ul>
                                <button
                                    className="bg-black text-white rounded-xl px-5 py-2 hover:bg-white hover:text-black"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openContactModal();
                                    }}
                                >
                                    Know More
                                </button>
                            </div>
                            <div className='hidden md:block'>
                                <img src={saasImg} alt="SaaS Application" className='h-[400px] md:h-[600px] -mt-20 object-cover rounded-2xl' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default WhatWedo;
